<template>
  <div class="wrap">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/Tender' }">竞标中心</el-breadcrumb-item>
        <el-breadcrumb-item>标的详情</el-breadcrumb-item>

      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="main">
        <div class="info">
          <div class="info-left">
            <div class="name">{{tenderData.bidName}}</div>
            <div class="box1-wrap">
              <div class="box1">
                <span>标的价格：</span>
                <p class="price" :class="[tenderData.status === 'starting'? 'color1' : tenderData.status === 'notStart'? 'color2' : 'color3']">{{tenderData.bidPrice}}元</p>
              </div>
              <div class="box1">
                <span>竞标开始时间：</span>
                <p>{{tenderData.startTime}}</p>
              </div>
              <div class="box1">
                <span>竞标结束时间：</span>
                <p>{{tenderData.endTime}}</p>
              </div>

              <div class="box1">
                <span>税率：</span>
                <p>{{tenderData.taxRate}}%（价格均含税）</p>
              </div>

              <div class="box1">
                <span>交付日期：</span>
                <p>{{tenderData.deliveryDate.substring(0,10)}}</p>
              </div>

              <div class="box1">
                <span>产品类型：</span>
                <p>{{tenderData.categoryName}}</p>
              </div>
              <div class="box1">
                <span>发票类型：</span>
                <p>{{tenderData.invoiceType === '0'? '专用发票':'普通发票'}}</p>
              </div>

              <div class="box1">
                <span>订单模式：</span>
                <p>{{tenderData.orderProcess === 'HK'? '先货后款':'先款后货'}}</p>
              </div>

              <div class="box1" >
                <span>付款方式：</span>
                <!-- <p>{{tenderData.payMode === 'YHZZ'? '银行转账':'商业承兑汇票'}}</p> -->
                <p>{{tenderData.payMode}}</p>
              </div>

              <div class="box1" v-if="tenderData.payMode == 'CDHP'">
                <span>承兑期限：</span>
                <p>{{ tenderData.bankBillTerm ? tenderData.bankBillTerm + '个月' : '无限制'}}</p>
              </div>

                 <div class="box1">
                <span>合同编号：</span>
                <p>{{ tenderData.contractNumber}}</p>
              </div>

              <div class="box1 box2">
                <span>合同信息：</span>
                <!-- <p>{{tenderData.contractInfo}}</p> -->
                <p style="color: blue;cursor: pointer;" @click="showContractMode">查看合同模板</p>
              </div>
            </div>
          </div>
          <div class="info-right">
            <div class="count-down-box" :class="[tenderData.status === 'notStart'? 'bg1' : 'bg2']" v-if="tenderData.status !== 'ended'">
              <div class="count-down-info">
                <span v-if="tenderData.status === 'notStart'">竞标开始时间：</span>
                <span v-else>竞标结束时间：</span>
                <span class="time" v-if="day > 0">{{ day }}</span>
                <span class="unit" v-if="day > 0">天</span>
                <span class="time">{{ hour }}</span>
                <span class="unit">时</span>
                <span class="time">{{ minute }}</span>
                <span class="unit">分</span>
                <span class="time">{{ second }}</span>
                <span class="unit">秒</span>
              </div>
            </div>
            <div class="addCart click" v-if="tenderData.status === 'starting'" @click="onQuoteDialog('open')">立即报价</div>
            <div class="addCart readOnly" v-else>{{tenderData.status === 'notStart'? '立即报价' : '已结束 '+whetherBided}}</div>
          </div>
        </div>

        <!-- 采购产品 -->
        <div class="explain">
          <h3>采购产品</h3>
          <div class="explain-info">
            <el-table
              :data="tenderData.list"
              :header-cell-style="{background:'#f3f3f3','font-weight': 500,'color':'#666','padding':'12px 0px'}"
              style="width:870px">
              <el-table-column
                type="index"
                label="序号"
                width="70">
              </el-table-column>
              <el-table-column
                prop="productName"
                label="产品名称"
                width="200">
              </el-table-column>
              <el-table-column
                prop="productBrand"
                label="产品品牌">
              </el-table-column>
              <el-table-column
                prop="productType"
                label="产品型号">
              </el-table-column>
              <el-table-column
                prop="specification"
                label="产品规格">
              </el-table-column>
              <el-table-column
                prop="number"
                label="采购量">
              </el-table-column>
              <el-table-column
                prop="unit"
                label="单位">
              </el-table-column>
              <el-table-column
                label="单价(含税)"
                width="120">
                <template slot-scope="scope">
                  {{scope.row.unitPrice}}元/{{scope.row.unit}}
                </template>
              </el-table-column>
              <el-table-column
                width="300"
                prop="tbpRequire"
                label="要求及说明">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 订单流程 -->
        <div v-if="orderInfo"
            class="explain">
          <h3>订单流程</h3>
          <div v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0"
               style="background:#fff;padding:20px 20px 10px" >
            <template v-if="tenderData.orderProcess === 'HK'">
            <el-steps :active="flowStep" align-center>
            <el-step title="确认订单"></el-step>
              <el-step title="合同签署"></el-step>
              <el-step title="供应商发货"></el-step>
              <el-step title="买家签收"></el-step>
              <el-step title="供应商开发票"></el-step>
              <el-step title="买家付款"></el-step>
              <el-step title="供应商收款"></el-step>
              <!-- <el-step title="流程结束"></el-step> -->
            </el-steps>
            </template>
            <template v-else>
              <el-steps :active="flowStep" align-center>
                <el-step title="确认订单"></el-step>
                <el-step title="合同签署"></el-step>
                <el-step title="买家支付"></el-step>
                <el-step title="供应商确认收款、发货、开发票"></el-step>
                <el-step title="买家签收"></el-step>
              </el-steps>
            </template>
          </div>
          <div class="explain-info" v-else>
              {{orderInfo.orderStatu === 'QXC' ? '订单取消待确认' : '订单已取消成功'}}
          </div>
        </div>
        <!-- 报价说明 -->
        <div class="explain">
          <h3>报价说明</h3>
          <div class="explain-info">
            <div v-html="tenderData.bidRequire"></div>
          </div>
        </div>
        <!-- 货款结算与支付方式 -->
        <div class="explain">
          <h3>货款结算与支付方式</h3>
          <div class="explain-info">
            <div v-html="tenderData.delRequire"></div>
          </div>
        </div>
        <!-- 计价方式 -->
        <div class="explain">
          <h3>计价方式</h3>
          <div class="explain-info">
            <div v-html="tenderData.pricRequire"></div>
          </div>
        </div>
        <!-- 质量技术标准 -->
        <div class="explain">
          <h3>质量技术标准</h3>
          <div class="explain-info">
            <div v-html="tenderData.qualRequire"></div>
          </div>
        </div>
      </div>

      <div class="sidebar">
        <div class="publish">
          <h3>企业信息</h3>
          <div class="publish-info">
            <p class="name">{{ tenderData.clientName | hideTextInfo(3, 4) }}</p>
            <p>联系人：{{ tenderData.contacts | hideTextInfo(1, 0) }}</p>
            <p>联系电话：{{ tenderData.phone | hideTextInfo(3, 4) }}</p>
            <p>收货地址：{{ tenderData.deliveryAddress | hideTextInfo(2, 2) }}</p>
          </div>
        </div>
        <div class="publish"
             style="margin-top:20px">
          <h3>最新铝锭价</h3>
          <div class="publish-info">
            <p>南海灵通铝锭价(含票)：<span style="color: red;">￥{{lvPrice.taxPrice || 0}}</span></p>
            <p>铝锭价公布日期：{{lvPrice.publishDay || '--'}}</p>
          </div>
        </div>
        <div class="publish" style="margin-top:20px">
          <h3>已报价企业</h3>
          <div class="price-list">
            <div v-if="isLogin">
              <div class="price-item" v-for="(item, index) in proCliS" :key="index">
                <div class="title">
                  <span style="margin-right: 16px;" v-if="item.client_info_id === userInfo.enterpriseId">{{item.client_name}}</span>
                  <span style="margin-right: 16px;" v-else>{{item.client_name | hideTextInfo(3, 4)}}</span>
                </div>
                <span style="margin-right: 16px;">{{item.add_time}}</span>
                <span style="color: red;font-weight: bold;">￥{{item.price}}</span>
              </div>
            </div>
            <empty v-else text="登录后可见"></empty>
          </div>
        </div>
      </div>
    </div>

    <!-- 竞标中 -->
    <div class="bidding-loading" v-if="biddingLoading">
      <div class="loading-info">
        <div class="dotting">
          <div class="dotting-item dotting1"></div>
          <div class="dotting-item dotting2"></div>
          <div class="dotting-item dotting3"></div>
        </div>
        <p>竞标中</p>
      </div>
    </div>

    <!-- 报价框 -->
    <div class="quote-dialog" v-show="showQuoteDialog">
      <transition name="fade">
        <div class="dialog-body" v-show="showQuoteDialog">
          <div class="dialog-header">
            <div class="title">报价</div>
            <div class="close" @click="onQuoteDialog('close')"><i class="el-icon-close"></i></div>
          </div>
          <div class="dialog-content">
            <div class="dialog-content-in">
              <el-table
                :data="tenderData.list"
                :header-cell-style="{background:'#f3f3f3','font-weight': 500,'color':'#666','padding':'12px 0px'}"
                style="width: 100%"
                max-height="320">
                <el-table-column
                  type="index"
                  label="序号"
                  width="50">
                </el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称"
                  width="100">
                </el-table-column>
                <!-- <el-table-column
                  prop="productBrand"
                  label="产品品牌">
                </el-table-column>
                <el-table-column
                  prop="productType"
                  label="产品型号">
                </el-table-column>
                <el-table-column
                  prop="specification"
                  label="产品规格">
                </el-table-column> -->
                <el-table-column
                  prop="number"
                  label="采购量"
                   width="80">
                </el-table-column>
                <el-table-column
                  prop="unit"
                  label="单位"
                  width="80">
                </el-table-column>
                <el-table-column
                  label="单价(含税)"
                   width="150"
                  >
                  <template slot-scope="scope">
                    {{scope.row.unitPrice}}元/{{scope.row.unit}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="totalPrice"
                  label="参考价格"
                  width="120">
                </el-table-column>
                <el-table-column
                  label="报价单价"

                 >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.quotePriec"
                    :maxlength="13"
                     @blur="onQuoteBlur(scope.row, scope.$index)"


                          @input.native="keyup($event,scope.$index)"


                     >


                     </el-input>
                    <span class="quote-tips" :ref="'tips' + scope.$index"></span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="quoteTotalPrice"
                  label="报价总价"
               >
                </el-table-column>
              </el-table>
            </div>
            <div class="total">
              <p>总报价：<span>{{totalPrice}}元</span></p>
            </div>
          </div>
          <div class="dialog-footer">
            <el-button type="primary" class="btn" @click="submitQuote">立即报价</el-button>
          </div>
        </div>
      </transition>
    </div>

    <!-- 查看合同模板 -->
    <el-dialog title="合同模板" :visible.sync="contractDialogVisible" width="700px">
      <div v-html="contractTextModel"></div>
    </el-dialog>
    <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event"/>
  </div>
</template>

<script>
import { hideTextInfo } from "@/utils/util";
import empty from "../component/empty";
import {getSelectBiddingDetails, tbidPricesPrices, getBiddingProClis, upstart, isMyOrder, whetherBided, newestLvdingPrice, showContractMode} from '../api';
import CFCATool from '@/components/CFCATool';

export default {
  components: {
    empty,
    CFCATool
  },
  created() {
    this.tenderId = this.$route.query.id;
    this.getDetails();
    this.thisWhetherBided();
    this.newestLvdingPrice();
	var that = this;
	setTimeout(function(){
		//倒计时结束 计时一分半后 避免定时任务的延迟 再次查询竞标的状态！
		that.thisWhetherBided();
	},180000);
  },
  data() {
    return {
      whetherBided:'',
      isLogin: Boolean(localStorage.getItem("loginName")),
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      tenderId: undefined, //详情id
      tenderData: '', //详情
      day: 0,
      hour: "00",
      minute: "00",
      second: "00",
      _timeout: null,
      showQuoteDialog: false, //报价弹窗
      totalPrice: 0,
      proCliS: [], //已报价企业
      biddingLoading: false, //竞标中
      contractDialogVisible: false, // 合同弹窗
      //判断拍下的公司是否和登录人公司相同
      clientAlike:false,
      //当前商品成交的订单id
      orderId:undefined,
      //用户在该商品的角色
      customer: "",
      //订单类型
      orderType:"BI",
      flowStep: 0,
      orderInfo: null,
      certInfo: '',
      lvPrice: {},
      //合同模板
      contractTextModel:"",
    };
  },
  mounted() {

  },
  filters: {
    hideTextInfo,
  },
  methods: {
      keyup(e,val){

            //限制10位整数 与两位小数的正则规则
             if (e.target.value[0] === '.') {
                e.target.value = e.target.value.replace(/^(\.)/, '0$1');
            } else if (e.target.value[0] === '0' && /\d/.test(e.target.value[1])) {
                e.target.value = e.target.value.substring(1)
            } else if (e.target.value.includes('.')) {

              const arr = e.target.value.split('.')
                if (arr[0].length > 10) {
                    e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`
                this.tenderData.list[val].quotePriec = e.target.value

                }else{
                e.target.value = e.target.value.replace(/[^(\d\.)]/g, '')
                e.target.value = e.target.value.replace(/^(\d{1,12}\.?\d{0,2}).*/, '$1');
                this.tenderData.list[val].quotePriec = e.target.value
            }

            } else {
                e.target.value = e.target.value.replace(/[^\d]/g, '')
                e.target.value = e.target.value.replace(/^(\d{0,10}).*/, '$1');
                this.tenderData.list[val].quotePriec = e.target.value
            }
    },
              blurmethod(e,val){

            if (e.target.value[e.target.value.length - 1] === '.'){
                  e.target.value = e.target.value.substring(0, [e.target.value.length - 1])
                this.tenderData.list[val] = e.target.value

              }



},
    // 状态
    tenderStatus(startTime, endTime) {
      startTime = startTime.replace(/-/g, '/');
      endTime = endTime.replace(/-/g, '/');
      if (new Date(startTime) > new Date()) { // 即将开始
        return "notStart";
      }
      if (new Date(startTime) < new Date() && new Date(endTime) > new Date()) {
        return "starting";
      }
      if (new Date(endTime) < new Date()) {
        return "ended";
      }
    },
    // 获取详情
    getDetails() {
      const _this = this;
      getSelectBiddingDetails({
        id: _this.tenderId
      }).then((res) => {
        console.log(res,'res')
        if (res.errno == 0){
          if(_this.tenderStatus(res.data.startTime, res.data.endTime) === 'notStart') { // 即将开始
            res.data.status = 'notStart';
            _this.countdown(res.data.startTime);
          } else if(_this.tenderStatus(res.data.startTime, res.data.endTime) === 'starting') { // 进行中
            res.data.status = 'starting';
            _this.countdown(res.data.endTime);
          } else if(_this.tenderStatus(res.data.startTime, res.data.endTime) === 'ended') { // 已结束
            res.data.status = 'ended';
			//倒计时结束 计时一分半后 避免定时任务的延迟 再次查询竞标的状态！
			setTimeout(function(){
				_this.thisWhetherBided();
			},180000);
                _this.getQuoteCompany();
            isMyOrder({"id":this.tenderId,"orderType":this.orderType}).then(response=>{
              this.orderId=response.data.order.id
              this.clientAlike=response.data.clientAlike
              this.customer=response.data.customer
              this.orderInfo = response.data.order
              if (response.data.order) {
                  this.getFlowStep(response.data.order.orderStatus,  response.data.order.orderProcess);
              }
            })
          }
          // res.data.list = res.data.list.concat(res.data.list);
          _this.tenderData = res.data;
          
              //  if (  _this.tenderData.payMode=='YHZZ') {
              //     _this.tenderData.payMode = '银行转账'
              //   }
              //   if (  _this.tenderData.payMode=='CDHP') {
              //     _this.tenderData.payMode = '商业承兑汇票'
              //   }
              //      if (  _this.tenderData.payMode=='YHZZ|CDHP') {
              //     _this.tenderData.payMode = '银行转账|商业承兑汇票'
              //   }
              //       if (  _this.tenderData.payMode=='CDHP|YHZZ') {
              //     _this.tenderData.payMode = '商业承兑汇票|银行转账'
              //   }
                    
    let changePayMode =  this.tenderData.payMode.split('|').map((item)=>{
         if (item == 'YHZZ') {
        return    item = '银行转账'
          } else if (item == 'CDHP') {
        return   item = '商业承兑汇票'
          } else if (item == "YHHP") {
         return   item = '银行承兑汇票'
          }    
      })
 this.tenderData.payMode=changePayMode.join("|")
 

        }

      });
    },

      getFlowStep(orderStatus, orderProcess){
          ['DFWQ', 'DQS'].indexOf(orderStatus) >= 0 && (this.flowStep = 1);
          if(orderProcess === 'HK'){
              ['DFH'].indexOf(orderStatus) >= 0 && (this.flowStep = 2);
              ['DSH'].indexOf(orderStatus) >= 0 && (this.flowStep = 3);
              ['DKP'].indexOf(orderStatus) >= 0 && (this.flowStep = 4);
              ['DZF'].indexOf(orderStatus) >= 0 && (this.flowStep = 5);
              ['ZFC'].indexOf(orderStatus) >= 0 && (this.flowStep = 6);
              ['END'].indexOf(orderStatus) >= 0 && (this.flowStep = 7);
          }else{
              ['DZF'].indexOf(orderStatus) >= 0 && (this.flowStep = 2);
              ['DFH'].indexOf(orderStatus) >= 0 && (this.flowStep = 3);
              ['DSH'].indexOf(orderStatus) >= 0 && (this.flowStep = 4);
              ['END'].indexOf(orderStatus) >= 0 && (this.flowStep = 5);
          }
      },

    thisWhetherBided(){
      const _this = this;
      whetherBided({
        biddingId:_this.tenderId
      }).then(
              res =>{
                  console.log("----------------------",res.data.whetherBided)
                if (res.data.whetherBided == '您已中标') {
                  _this.whetherBided='您已中标';
                }else  if(res.data.whetherBided == '您未中标'){
                  _this.whetherBided='您未中标';
                }else  if(res.data.whetherBided == '您未参与'){
                  _this.whetherBided='您未参与';
                }else if(res.data.whetherBided == '成交处理中...'){
					_this.whetherBided='成交处理中...';
				}
              }

      )

    },
    // 已报价企业
    getQuoteCompany() {
      const _this = this;
      getBiddingProClis({
        id: _this.tenderId
      }).then((res) => {
        if (res.errno == 0){
          _this.proCliS = res.data.proCliS;
        }
      });
    },

    // 倒计时
    countdown(time) {
      const _this = this;
      const end = Date.parse(time.replace(/-/g, '/'));
      const now = Date.parse(new Date());
      const msec = end - now;

      if (msec <= 0) {
        clearTimeout(this._timeout);
		setTimeout(function(){
			//倒计时结束 计时三分钟后 避免定时任务的延迟 再次查询竞标的状态！
			console.log("我来执行了--------------------------");
			_this.thisWhetherBided();
		},180000);
        if (_this.tenderData.status === "notStart") {
          _this.tenderData.status = "starting";
          _this.countdown(_this.tenderData.endTime);
        } else if (_this.tenderData.status === "starting") {
          _this.tenderData.status = "ended";
          _this.day = 0;
          _this.hour = "00";
          _this.minute = "00";
          _this.second = "00";
        }


      } else {
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt((msec / 1000 / 60 / 60) % 24);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        this.day = d;
        this.hour = h > 9 ? h : "0" + h;
        this.minute = m > 9 ? m : "0" + m;
        this.second = s > 9 ? s : "0" + s;
        this._timeout = setTimeout(function () {
          if (_this.tenderId) {
            _this.getQuoteCompany();
          }
          _this.countdown(time);
        }, 1000);


      }
    },
	// dian(){
	// 	setTimeout(function(){

	// 		console.log("我来修改状态了：");

	// 		upstart({
	// 			biddingId: 360
	// 		}).then(res=>{
	// 			console.log("调用结束");
	// 		});
	// 	},1000);
	// },
    // 显示/隐藏报价框
    onQuoteDialog(type) {
      if(type === 'open') {
        this.showQuoteDialog = true;
      } else {
        this.showQuoteDialog = false;
      }
    },
    // 监听报价金额范围
    onQuoteBlur(row, index) {
      if(parseFloat(row.quoteTotalPrice) > row.totalPrice) {
        this.$refs['tips'+index].innerHTML = '报价总价不能高于给定的参考价';
      } else if(parseFloat(row.quoteTotalPrice) < (row.totalPrice * 0.7)) {
        this.$refs['tips'+index].innerHTML = '报价总价不能低于给定参考价的70%';
      } else {
        this.$refs['tips'+index].innerHTML = '';
      }
    },
    // 提交报价
    submitQuote() {
      const _this = this;
      // 判断登录状态
      if (!_this.isLogin) {
        _this.$confirm('当前未登录, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$router.push({
            path: '/home/Login'
          });
        });
        return
      }

      if(this.$refs.CFCARef.checkIsIE()){
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo()
        if (!this.certInfo) return ;
        //  this.$message.info('未检测到证书信息')
      }
      localStorage.setItem('certInfo', this.certInfo);
      let newList = [];
      let is_true = true;
      this.tenderData.list.forEach((item, index) => {
        if(item.quotePriec) {
          if(parseFloat(item.quoteTotalPrice) > item.totalPrice) {
            is_true = false;
            _this.$refs['tips'+index].innerHTML = '报价总价不能高于给定的参考价';
          } else if(parseFloat(item.quoteTotalPrice) < (item.totalPrice * 0.7)) {
            is_true = false;
            _this.$refs['tips'+index].innerHTML = '报价总价不能低于给定参考价的70%';
          } else {
            _this.$refs['tips'+index].innerHTML = '';
            newList.push({bidProduct: item.id, proPrice: item.quoteTotalPrice});
          }
        } else {
          is_true = false;
          _this.$refs['tips' + index].innerHTML = '报价单价不能为空';
        }
      });

      if(is_true === false) {
        return;
      }

      _this.biddingLoading = true;
      tbidPricesPrices({
        bidId: _this.tenderId,
        priceList: newList
      }).then((res) => {
        _this.biddingLoading = false;
        if (res.errno == 0){
          _this.showQuoteDialog = false;
          _this.$message.success('竞标成功！');
        }
      });
    },
    newestLvdingPrice() {
      newestLvdingPrice().then(response=>{
        this.lvPrice=response.data
      })
    },
    //查询合同模板
    showContractMode(){
      if(this.contractTextModel==""){
        showContractMode({"contractNo":this.tenderData.contractInfo}).then(res=>{
          this.contractTextModel=res.data.contractTextModel
          this.contractDialogVisible = true
        })
      }else {
        this.contractDialogVisible = true
      }
    },

  },
  watch: {
    'tenderData.list': {
      handler: function() {
        let totalPrice = 0;
        let list = this.tenderData.list;
        for(let i = 0; i < list.length; i++) {
          if(list[i].quotePriec) {
            list[i].quotePriec = list[i].quotePriec.replace(/[^0-9.]*/g, '');
            list[i].quoteTotalPrice = parseFloat(list[i].quotePriec * list[i].number).toFixed(2);
            totalPrice += parseFloat(list[i].quoteTotalPrice);
          } else {
            list[i].quoteTotalPrice = '';
          }
        }
        this.totalPrice = parseFloat(totalPrice).toFixed(2);
      },
      deep: true
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    clearTimeout(this._timeout); // 当离开页面时，清除倒计时
    localStorage.removeItem('certInfo');
  }
};
</script>

<style lang="less" scoped>
@deep: ~">>>";
.head_h3 {
  position: relative;
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
  color: #333333;
  margin: 0;

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 4px;
    height: 20px;
    background: #008cf4;
    margin-top: -10px;
  }
}
.fade-enter, .fade-leave-to {
  transform: translateY(-14px);
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.8s ease;
}
.fade-leave-active {
  transition: all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.wrap {
  width: 1200px;
  margin: 0 auto 30px auto;

  .breadcrumb {
    margin: 18px 0;
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    overflow: hidden;
    position: relative;

    .main {
      flex: 1;

      .info {
        background: #fff;
        padding: 25px 22px 30px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-left {
          .name {
            width: 370px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .box1-wrap {
            margin-top: 22px;

            .box1 {
              display: flex;
              align-items: flex-end;
              margin-bottom: 22px;
              line-height: 1;

              span {
                color: #999999;
                font-size: 14px;
              }
              p {
                color: #333333;
                font-size: 14px;

                &.price {
                  font-weight: bold;
                  font-size: 20px;

                  &.color1 {
                    color: #ff4400;
                  }
                  &.color2 {
                    color: #008cf4;
                  }
                  &.color3 {
                    color: #666666;
                  }
                }
              }
              &.box2 {
                align-items: flex-start;

                p {
                  width: 290px;
                }
              }
            }
          }
        }
        .info-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .count-down-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 379px;
            height: 42px;
            border-radius: 4px;

            &.bg1 {
              background: #55c176;
            }
            &.bg2 {
              background: #ff5a48;
            }
            .count-down-info {
              display: flex;
              align-items: center;
              font-size: 18px;
              color: #ffffff;

              .unit {
                color: #ffd44d;
                margin: 0 6px;
              }
            }
          }
          .addCart {
            width: 210px;
            height: 46px;
            text-align: center;
            line-height: 44px;
            border-radius: 4px;
            margin-top: 25px;
            cursor: pointer;
            font-size: 16px;
            color: #ffffff;
            box-sizing: border-box;

            &.click {
              color: #008cf4;
              border: 1px solid #008cf4;

              &:hover {
                background: #008cf4;
                color: #ffffff;
              }
            }
            &.readOnly {
              background-image: linear-gradient(to right, #d7d4d4, #c2c0c1);
            }
          }
        }
      }
      .explain {
        background: #fff;
        margin-top: 16px;

        h3 {
          .head_h3();
        }
        .explain-info {
          padding: 24px;

          & @{deep} {
            color: #333333;
            font-size: 14px;
            line-height: 24px;
          }
          & @{deep} img {
            width: 100%;
          }
        }
      }
    }

    .sidebar {
      width: 287px;
      margin-left: 15px;

      .publish {
        background: #fff;

        h3 {
          .head_h3();
        }
        .publish-info {
          padding: 24px;
          color: #333333;

          p {
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 17px;

            &.name {
              font-size: 16px;
              margin-bottom: 24px;
            }
          }
        }
        .price-list {
          padding: 24px;
          box-sizing: border-box;

          .price-item {
            padding: 5px 0;

            .title {
              margin-bottom: 6px;
              font-weight: bold;
            }
          }
        }
      }
      .bidding {
        background: #fff;
        margin-top: 17px;

        h3 {
          .head_h3();
        }
        .bidding-list {
          padding: 24px 22px;

          .bidding-item {
            display: flex;
            margin-bottom: 45px;

            .bidding-image {
              width: 80px;
              height: 80px;

              img {
                width: 100%;
              }
            }
            .bidding-info {
              flex: 1;
              margin-left: 17px;

              p {
                font-size: 14px;
                color: #333333;
                line-height: 20px;

                .price {
                  color: #ff4400;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .bidding-status {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .good-box {
        position: absolute;
        top: 11px;
        left: 14px;
        right: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 164px;

        &.bg1 {
          background: #e6ffde;
        }
        &.bg2 {
          background: #ffebe9;
        }
        .good-box-in {
          display: flex;
          width: 305px;
          background: #ffffff;
          padding: 21px 15px;
          box-sizing: border-box;

          .img-box {
            width: 80px;
            height: 80px;
            overflow: hidden;
          }
          .info {
            margin-left: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .name {
              width: 160px;
              font-size: 14px;
              color: #333333;
              line-height: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .info-item {
              font-size: 12px;
              color: #888888;
              line-height: 1;

              &:last-child {
                margin-top: -4px;
              }
              .tit {
                display: inline-block;
                width: 50px;
                text-align: justify;
                text-align-last: justify;
              }
              .sign {
                color: #ff4400;
              }
              .price {
                font-size: 18px;
                color: #ff4400;
              }
            }
          }
        }
      }
      .status-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .status-box-info {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .el-icon-success {
            font-size: 44px;
            color: #55c176;
            margin-right: 10px;
          }
          .el-icon-error {
            font-size: 44px;
            color: #d91516;
            margin-right: 10px;
          }
          .txst {
            font-size: 16px;
          }
          .desc {
            font-size: 13px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  // 竞标中
  .bidding-loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-info {
      width: 150px;
      background: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      text-align: center;
      box-sizing: border-box;

      .dotting {
        margin-bottom: 30px;

        .dotting-item {
          width: 10px;
          height: 10px;
          margin: 0 6px;
          background-color: #2ba2c5;
          border-radius: 100%;
          display: inline-block;
          -webkit-animation: bouncedelay 2s infinite ease-in-out;
          animation: bouncedelay 2s infinite ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .dotting1 {
          -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
        }
        .dotting2 {
          -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
        }
        @-webkit-keyframes bouncedelay {
          0%,
          80%,
          100% {
            opacity: 0.5;
          }
          40% {
            opacity: 1;
          }
        }
        @keyframes bouncedelay {
          0%,
          80%,
          100% {
            opacity: 0.5;
          }
          40% {
            opacity: 1;
          }
        }
      }
      p {
        font-size: 15px;
      }
    }
  }
  // 报价框
  .quote-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;


    .dialog-body {
      position: relative;
      border-radius: 2px;
      box-shadow: 0 1px 3px rgba(0 0 0 / 30%);
      box-sizing: border-box;
      width: 1040px;
      // height: 437px;
      background: #FFF;

      .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 0 15px 0 0px;

        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          position: relative;
          padding-left: 15px;

          &::after {
            position: absolute;
            left: 0;
            top: -1px;
            width: 5px;
            height: 23px;
            content: '';
            background: #008cf4;
          }
        }
        .close {
          width: 26px;
          height: 26px;
          border: 1px solid #D9D9D9;
          border-radius: 4px;
          font-size: 20px;
          color: #333333;
          text-align: center;
          line-height: 24px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      .dialog-content {
        display: flex;
        flex-direction: column;
        width: 1010px;
        // height: 286px;
        margin: 15px auto;
        box-sizing: border-box;
        overflow: hidden;

        .dialog-content-in {
          border: 1px solid #EBEEF5;

          .el-table::before {
            height: 0;
          }
          .quote-tips {
            display: block;
            color: #d91516;
            font-size: 12px;
            line-height: 16px;
            margin-top: 5px;
          }
        }
        .total {
          text-align: right;
          font-size: 14px;
          color: #333333;
          padding-right: 30px;
          margin-top: 14px;

          span {
            font-size: 20px;
            color: #ff4400;
          }
        }
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        padding-bottom: 20px;

        .btn {
          width: 200px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          padding: 0;
        }
      }
    }
  }
}
</style>
