var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/Tender" } } },
                [_vm._v("竞标中心")]
              ),
              _c("el-breadcrumb-item", [_vm._v("标的详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "info-left" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.tenderData.bidName)),
              ]),
              _c("div", { staticClass: "box1-wrap" }, [
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("标的价格：")]),
                  _c(
                    "p",
                    {
                      staticClass: "price",
                      class: [
                        _vm.tenderData.status === "starting"
                          ? "color1"
                          : _vm.tenderData.status === "notStart"
                          ? "color2"
                          : "color3",
                      ],
                    },
                    [_vm._v(_vm._s(_vm.tenderData.bidPrice) + "元")]
                  ),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("竞标开始时间：")]),
                  _c("p", [_vm._v(_vm._s(_vm.tenderData.startTime))]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("竞标结束时间：")]),
                  _c("p", [_vm._v(_vm._s(_vm.tenderData.endTime))]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("税率：")]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.tenderData.taxRate) + "%（价格均含税）"),
                  ]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("交付日期：")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.tenderData.deliveryDate.substring(0, 10))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("产品类型：")]),
                  _c("p", [_vm._v(_vm._s(_vm.tenderData.categoryName))]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("发票类型：")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.tenderData.invoiceType === "0"
                          ? "专用发票"
                          : "普通发票"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("订单模式：")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.tenderData.orderProcess === "HK"
                          ? "先货后款"
                          : "先款后货"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("付款方式：")]),
                  _c("p", [_vm._v(_vm._s(_vm.tenderData.payMode))]),
                ]),
                _vm.tenderData.payMode == "CDHP"
                  ? _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("承兑期限：")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.tenderData.bankBillTerm
                              ? _vm.tenderData.bankBillTerm + "个月"
                              : "无限制"
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("合同编号：")]),
                  _c("p", [_vm._v(_vm._s(_vm.tenderData.contractNumber))]),
                ]),
                _c("div", { staticClass: "box1 box2" }, [
                  _c("span", [_vm._v("合同信息：")]),
                  _c(
                    "p",
                    {
                      staticStyle: { color: "blue", cursor: "pointer" },
                      on: { click: _vm.showContractMode },
                    },
                    [_vm._v("查看合同模板")]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "info-right" }, [
              _vm.tenderData.status !== "ended"
                ? _c(
                    "div",
                    {
                      staticClass: "count-down-box",
                      class: [
                        _vm.tenderData.status === "notStart" ? "bg1" : "bg2",
                      ],
                    },
                    [
                      _c("div", { staticClass: "count-down-info" }, [
                        _vm.tenderData.status === "notStart"
                          ? _c("span", [_vm._v("竞标开始时间：")])
                          : _c("span", [_vm._v("竞标结束时间：")]),
                        _vm.day > 0
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.day)),
                            ])
                          : _vm._e(),
                        _vm.day > 0
                          ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                          : _vm._e(),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.hour)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.minute)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.second)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.tenderData.status === "starting"
                ? _c(
                    "div",
                    {
                      staticClass: "addCart click",
                      on: {
                        click: function ($event) {
                          return _vm.onQuoteDialog("open")
                        },
                      },
                    },
                    [_vm._v("立即报价")]
                  )
                : _c("div", { staticClass: "addCart readOnly" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tenderData.status === "notStart"
                          ? "立即报价"
                          : "已结束 " + _vm.whetherBided
                      )
                    ),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("采购产品")]),
            _c(
              "div",
              { staticClass: "explain-info" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "870px" },
                    attrs: {
                      data: _vm.tenderData.list,
                      "header-cell-style": {
                        background: "#f3f3f3",
                        "font-weight": 500,
                        color: "#666",
                        padding: "12px 0px",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "70" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        width: "200",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "productBrand", label: "产品品牌" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "productType", label: "产品型号" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "specification", label: "产品规格" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "number", label: "采购量" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "unit", label: "单位" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "单价(含税)", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.unitPrice) +
                                  "元/" +
                                  _vm._s(scope.row.unit) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "300",
                        prop: "tbpRequire",
                        label: "要求及说明",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.orderInfo
            ? _c("div", { staticClass: "explain" }, [
                _c("h3", [_vm._v("订单流程")]),
                ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#fff",
                          padding: "20px 20px 10px",
                        },
                      },
                      [
                        _vm.tenderData.orderProcess === "HK"
                          ? [
                              _c(
                                "el-steps",
                                {
                                  attrs: {
                                    active: _vm.flowStep,
                                    "align-center": "",
                                  },
                                },
                                [
                                  _c("el-step", {
                                    attrs: { title: "确认订单" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "合同签署" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商发货" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家签收" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商开发票" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家付款" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商收款" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "el-steps",
                                {
                                  attrs: {
                                    active: _vm.flowStep,
                                    "align-center": "",
                                  },
                                },
                                [
                                  _c("el-step", {
                                    attrs: { title: "确认订单" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "合同签署" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家支付" },
                                  }),
                                  _c("el-step", {
                                    attrs: {
                                      title: "供应商确认收款、发货、开发票",
                                    },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家签收" },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  : _c("div", { staticClass: "explain-info" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.orderInfo.orderStatu === "QXC"
                              ? "订单取消待确认"
                              : "订单已取消成功"
                          ) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("报价说明")]),
            _c("div", { staticClass: "explain-info" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.tenderData.bidRequire) },
              }),
            ]),
          ]),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("货款结算与支付方式")]),
            _c("div", { staticClass: "explain-info" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.tenderData.delRequire) },
              }),
            ]),
          ]),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("计价方式")]),
            _c("div", { staticClass: "explain-info" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.tenderData.pricRequire) },
              }),
            ]),
          ]),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("质量技术标准")]),
            _c("div", { staticClass: "explain-info" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.tenderData.qualRequire) },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "publish" }, [
            _c("h3", [_vm._v("企业信息")]),
            _c("div", { staticClass: "publish-info" }, [
              _c("p", { staticClass: "name" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("hideTextInfo")(_vm.tenderData.clientName, 3, 4)
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  "联系人：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(_vm.tenderData.contacts, 1, 0)
                    )
                ),
              ]),
              _c("p", [
                _vm._v(
                  "联系电话：" +
                    _vm._s(_vm._f("hideTextInfo")(_vm.tenderData.phone, 3, 4))
                ),
              ]),
              _c("p", [
                _vm._v(
                  "收货地址：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(
                        _vm.tenderData.deliveryAddress,
                        2,
                        2
                      )
                    )
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "publish", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("最新铝锭价")]),
              _c("div", { staticClass: "publish-info" }, [
                _c("p", [
                  _vm._v("南海灵通铝锭价(含票)："),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("￥" + _vm._s(_vm.lvPrice.taxPrice || 0)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "铝锭价公布日期：" + _vm._s(_vm.lvPrice.publishDay || "--")
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "publish", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("已报价企业")]),
              _c(
                "div",
                { staticClass: "price-list" },
                [
                  _vm.isLogin
                    ? _c(
                        "div",
                        _vm._l(_vm.proCliS, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "price-item" },
                            [
                              _c("div", { staticClass: "title" }, [
                                item.client_info_id ===
                                _vm.userInfo.enterpriseId
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [_vm._v(_vm._s(item.client_name))]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("hideTextInfo")(
                                              item.client_name,
                                              3,
                                              4
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "16px" } },
                                [_vm._v(_vm._s(item.add_time))]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("￥" + _vm._s(item.price))]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("empty", { attrs: { text: "登录后可见" } }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm.biddingLoading
        ? _c("div", { staticClass: "bidding-loading" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showQuoteDialog,
              expression: "showQuoteDialog",
            },
          ],
          staticClass: "quote-dialog",
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showQuoteDialog,
                    expression: "showQuoteDialog",
                  },
                ],
                staticClass: "dialog-body",
              },
              [
                _c("div", { staticClass: "dialog-header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("报价")]),
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function ($event) {
                          return _vm.onQuoteDialog("close")
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ]),
                _c("div", { staticClass: "dialog-content" }, [
                  _c(
                    "div",
                    { staticClass: "dialog-content-in" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tenderData.list,
                            "header-cell-style": {
                              background: "#f3f3f3",
                              "font-weight": 500,
                              color: "#666",
                              padding: "12px 0px",
                            },
                            "max-height": "320",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productName",
                              label: "产品名称",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "number",
                              label: "采购量",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "unit", label: "单位", width: "80" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "单价(含税)", width: "150" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.unitPrice) +
                                        "元/" +
                                        _vm._s(scope.row.unit) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "totalPrice",
                              label: "参考价格",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "报价单价" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { maxlength: 13 },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onQuoteBlur(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.keyup($event, scope.$index)
                                        },
                                      },
                                      model: {
                                        value: scope.row.quotePriec,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "quotePriec", $$v)
                                        },
                                        expression: "scope.row.quotePriec",
                                      },
                                    }),
                                    _c("span", {
                                      ref: "tips" + scope.$index,
                                      staticClass: "quote-tips",
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quoteTotalPrice",
                              label: "报价总价",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "total" }, [
                    _c("p", [
                      _vm._v("总报价："),
                      _c("span", [_vm._v(_vm._s(_vm.totalPrice) + "元")]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "dialog-footer" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.submitQuote },
                      },
                      [_vm._v("立即报价")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "合同模板",
            visible: _vm.contractDialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.contractDialogVisible = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.contractTextModel) } })]
      ),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-info" }, [
      _c("div", { staticClass: "dotting" }, [
        _c("div", { staticClass: "dotting-item dotting1" }),
        _c("div", { staticClass: "dotting-item dotting2" }),
        _c("div", { staticClass: "dotting-item dotting3" }),
      ]),
      _c("p", [_vm._v("竞标中")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }